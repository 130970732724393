<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
  <!-- single-export="Dapat Ditinjau" -->
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_kepmhs',
      headers: [
        [{
          label: 'Aspek Pengukuran Kepuasan',
          dataKey: 'aspek_pengukuran_kepuasan',
          order: 1,
          attributes: { rowspan: 2 },
        }, {
          label: 'Objek Kepuasan Mahasiswa',
          attributes: { colspan: 3 },
        }, {
          label: 'Tindak Lanjut',
          dataKey: 'tindak_lanjut',
          order: 5,
          attributes: { rowspan: 2 },
        }],
        [{
          label: 'Kinerja Mengajar DTPS',
          dataKey: 'kinerja_mengajar_dtps',
          order: 2,
          dataAttributes: { class: 'text-center align-middle' },
        }, {
          label: 'Layanan Administrasi Akademik oleh PS',
          dataKey: 'layanan_administrasi_akademik_oleh_ps',
          order: 3,
          dataAttributes: { class: 'text-center align-middle' },
        }, {
          label: 'Prasarana dan Sarana Pembelajaran di PS',
          dataKey: 'prasarana_dan_sarana_pembelajaran_di_ps',
          order: 4,
          dataAttributes: { class: 'text-center align-middle' },
        }]
      ],
      form: {
        inputs: [{
          label: 'Aspek Pengukuran Kepuasan',
          name: 'aspek_pengukuran_kepuasan',
          dataKey: 'aspek_pengukuran_kepuasan',
          type: 'textarea',
          rules: 'required'
        }, {
          label: 'Kinerja Mengajar DTPS',
          name: 'kinerja_mengajar_dtps',
          dataKey: 'kinerja_mengajar_dtps',
          type: 'number',
        }, {
          label: 'Layanan Administrasi Akademik oleh PS',
          name: 'layanan_administrasi_akademik_oleh_ps',
          dataKey: 'layanan_administrasi_akademik_oleh_ps',
          type: 'number',
        }, {
          label: 'Prasarana dan Sarana Pembelajaran di PS',
          name: 'prasarana_dan_sarana_pembelajaran_di_ps',
          dataKey: 'prasarana_dan_sarana_pembelajaran_di_ps',
          type: 'number',
        }, {
          label: 'Tindak Lanjut',
          name: 'tindak_lanjut',
          dataKey: 'tindak_lanjut',
          type: 'textarea',
        }],
      },
    }
  },
}
</script>
